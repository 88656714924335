export class Message {
    id: string | null = null;
    uid: string | null = null;
    text: string | null = null;
    audio: string | null = null;
    call: any = null;
    audioDuration: number | null = null;
    startCall: Date | null = null;
    endCall:Date | null = null;
    edited: boolean = false;
    type: string = "text";
    images: string[] = [];
    createAt: Date = null;
    isSupport: boolean = false;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? null;
        this.uid = data?.uid ?? null;
        this.text = data?.text ?? null;
        this.audio = data?.audio ?? null;
        this.call = data?.call ?? null;
        this.audioDuration = data?.audioDuration ?? null;
        this.startCall = data?.startCall ?? null;
        this.edited = data?.edited ?? false;
        this.isSupport = data?.isSupport ?? false;
        this.type = data?.type ?? "text";
        this.images = data?.images != null ? data.images : [];
        this.endCall = data?.endCall ? (typeof data?.endCall === "string" ? new Date(data.endCall) : (data.endCall instanceof Date ? data.endCall : new Date(data.endCall.toDate().toString()))) : null;
        this.createAt = data?.createAt ? (typeof data?.createAt === "string" ? new Date(data.createAt) : (data.createAt instanceof Date ? data.createAt : new Date(data.createAt.toDate().toString()))) : null;
    }

    toJson(): Message {
        return Object.assign({}, this);
    }
}
