import moment from "moment";
import React, { useState } from "react";
import Bar from "./Bar";
import useAudioPlayer from './useAudioPlayer';

const Audio = ({ src, mine, id, audioDuration, style }: { src: string; mine: boolean; id: string; audioDuration: number; style: React.CSSProperties }) => {
  const { curTime, duration, playing, setPlaying } = useAudioPlayer(id);

 
  return (
    <div className="player" style={{ width: "100%", height: "inherit", display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"20px 0" }}>
      <audio id={"audio" + id} >
        <source src={src} />
        Your browser does not support the <code>audio</code> element.
      </audio>
        <Bar isMine={mine} style={style} isPlaying={playing} togglePlay={setPlaying} curTime={curTime} duration={audioDuration} width={audioDuration} />
    </div>
  );
}

export default Audio;