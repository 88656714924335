import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import Text from '../../components/data-display/text';
import { RootState } from "../../redux/index.reducers";
import { Message } from "../../models/private-message";
import { isEmojiOnly, isToday } from "../../utils/methods.utils";
import Audio from '../../components/audio-handler/Audio';
import useTheme from "@material-ui/core/styles/useTheme";
import moment from "moment";
import { tasks } from "../../utils/firebase.utils";
import { AdminMessage } from "../../utils/firebase.utils";
import AttachmentHandler from "../../components/attachment-handler/attachment-handler";
import { Tooltip, Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import call from "../../images/icons/missed_call.png";
import call2 from "../../images/icons/call-duration.png";
import { auth, users } from "../../utils/firebase.utils";
import { UserData } from "../../models/user-data";
import { useMatch } from "@reach/router";
import { MessageStatus } from "../../models/private-messaging-instance";
import {AiFillEye} from "react-icons/all";
import firebase from "firebase";

const useStyles = makeStyles(theme => ({
    backgroundTooltip: {
        borderRadius: "14px",
        background: "rgb(30,30,30)",
        padding: "6px 8px"
    }
}));

const MessageListItem = ({ message, previous, next ,messages}: { message: Message, previous: Message, next: Message,messages:any }) => {

    const { userData } = useSelector((state: RootState) => state.userData);
    const theme = useTheme();
    const classes = useStyles();
    let isMine = message?.uid === userData?.uid;
    let isLast = next == null;
    const urlRE= new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
    let nextContinue = next?.uid == message.uid && (next != null && moment(next.createAt).subtract(moment(message.createAt).get("hours"), "hours").get("hours") < 1);
    let prevContinue = previous?.uid == message.uid && (previous != null && moment(message.createAt).subtract(moment(previous.createAt).get("hours"), "hours").get("hours") < 1);
    let isOnlyEmoji = isEmojiOnly(message.text.trim());
    const user = auth.currentUser;
    const start =  message?.call?.startCall ? (typeof message?.call?.startCall === "string" ? new Date(message?.call?.startCall) : (message?.call?.startCall instanceof Date ? message?.call?.startCall : new Date(message?.call?.startCall.toDate().toString()))) : null;
    const end = message?.call?.endCall ? (typeof message?.call?.endCall === "string" ? new Date(message?.call?.endCall) : (message?.call?.endCall instanceof Date ? message?.call?.endCall : new Date(message?.call?.endCall.toDate().toString()))) : null;
    const startTime = moment(start, "HH:mm:ss");
    const endTime = moment(end, "HH:mm:ss");
    const time = moment.duration(endTime.diff(startTime));
    const msgMatch = useMatch('/messaging/:id');
    let taskId = msgMatch?.id?.split("_")[0];
    let offerId = msgMatch?.id?.split("_")[1];
    const [lastRead, setLastRead] = React.useState(null);
    const [read, setRead] = React.useState(null);
    const [otherUser, setOtherUser] = React.useState(null);
    const [caller, setCaller] = React.useState('');
    const [messagingAdmin, setMessagingAdmin] = useState<MessageStatus[]>([]);
    const [isAdminType, setIsAdminType] = useState<boolean>(false);



    
    const getMessaging = () => {
        let isAdmin: boolean = msgMatch.id.includes('admin') ? true : false;
    setIsAdminType(isAdmin)
    if(!isAdmin){
        let t: MessageStatus[] = [];
        tasks.doc(taskId).collection("offers").doc(offerId).collection("messaging").doc(msgMatch?.id).get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    t.push(new MessageStatus(data));
                    setLastRead(data?.lastRead[userData?.uid])
                    setRead(data?.unRead)
                    if(data.poster== userData.uid){
                        setOtherUser(data.tasker);
                        getUserName(data.tasker);
                    }else{
                        setOtherUser(data.poster);
                        getUserName(data.poster);

                    }
                }
            });
    }
       
    };


   
  
    React.useEffect(() => {
        getMessaging()
        // getMessagingAdmin()
    }, [msgMatch?.id])

   const getUserName= async (otherUser)=>{
    if (otherUser) {
      await users.doc(otherUser).get().then((doc) => {
          setCaller(doc?.data().firstName);
        })
    }
   }

    const getTime= (()=>{
        if( time?.asSeconds() < 60){
            return time?.asSeconds() + " "+'sec';
        }else{
            return time?.asMinutes().toString().slice(0,1) +" "+'min';
        }
        })
        


    return (
        <>
        {message?.isSupport&& !prevContinue   && 
         <div
         style={{
           marginLeft: '30px'
         }}
       >
        <Text variant={"caption"} bold component={"p"} style={{color:"grey"}} >Admin</Text>
        </div>
        }
        <Tooltip
            placement={isMine ? "left" : "right"}
            classes={{
                tooltip: classes.backgroundTooltip
            }}
            title={<Text variant={"caption"} medium style={{ color: "white", padding: "4px 8px" }}>
                {`${isToday(message.createAt) ? "Today" : moment(message.createAt).format("MMM DD")} at ${moment(message.createAt).format("hh:mm A")}`}
            </Text>}
            disableFocusListener style={{ background: "black" }}
        >
            <div style={{
                maxWidth: "400px",
                width: 'fit-content',
                marginTop: "2px",
                marginBottom: (isLast || !nextContinue) ? "14px" : "0px",
                marginLeft: "0px", marginRight: "24px",
                padding: message?.type == "image" || message?.type == "audio" || isOnlyEmoji ? "0" : "7px 14px",
                borderTopLeftRadius: (prevContinue && !isMine) ? "4px" : "20px",
                borderTopRightRadius: (prevContinue && isMine) ? "4px" : "20px",
                borderBottomLeftRadius: (nextContinue && !isMine) ? "4px" : "20px",
                borderBottomRightRadius: (nextContinue && isMine) ? "4px" : "20px",
                background: message?.type == "image" || message?.type == "audio" || isOnlyEmoji ? null : (isMine ? theme.palette.primary.main : "rgba(224,224,224,0.6)")
            }}>
                {message?.type === "text" &&
                 message.text.match(urlRE)?
                 <Text variant="body2">
                 <a  href={message.text.includes('http')? message.text  :  window.location.protocol + "//"+message.text} style={{ color: isOnlyEmoji ? null : (isMine ? "white" : "black") }}>{message.text}</a>
                 </Text>
                 :
                 <Text variant={isOnlyEmoji ? "h3" : "body2"} component={"p"} style={{ color: isOnlyEmoji ? null : (isMine ? "white" : "black") }}>{message?.type != 'call' && message?.type != 'audio' ?message.text:""}</Text>
                 }
                {message?.type === "audio" &&
                    <Audio
                        style={{
                            width:"300px",
                            // height:"40px",
                            padding: "7px 14px",
                            borderTopLeftRadius: (prevContinue && !isMine) ? "4px" : "20px",
                            borderTopRightRadius: (prevContinue && isMine) ? "4px" : "20px",
                            borderBottomLeftRadius: (nextContinue && !isMine) ? "4px" : "20px",
                            borderBottomRightRadius: (nextContinue && isMine) ? "4px" : "20px",
                        }}
                        mine={isMine} id={message.id} src={message?.audio} audioDuration={message?.audioDuration} />
                        }

                {message?.type === "call" && !message?.call?.isAnswered && <>
                    <Grid spacing={2} container>
                        <Grid item xs={2} md={2} lg={2} xl={2}>
                            <div style={{ backgroundColor: "rgba(235, 80, 60, 1.0)", borderRadius: "175px", width: "44px", paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px" }}>
                                <img src={call} style={{ height: " 30px", width: "30px", display: " flex", justifyContent: "center", marginLeft: "4px", }}>
                                </img>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <Text variant={isOnlyEmoji ? "h3" : "body2"} component={"p"} style={{ color: isOnlyEmoji ? null : (isMine ? "white" : "black") }}> {isMine ? `${caller} missed your call.` : "You missed a call."}</Text>
                        </Grid>
                    </Grid>
                </>
                }

                {message?.type === "call" && message?.call?.isAnswered && <>
                    <Grid spacing={2} container>
                        <Grid item xs={2} md={2} lg={2} xl={2}>
                            <div style={{ backgroundColor: isMine?"gray":"#4E3EFD", borderRadius: "175px", width: "44px", paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px" }}>
                                <img src={call2} style={{ height: " 30px", width: "30px", display: " flex", justifyContent: "center", marginLeft: "4px", }}>
                                </img>
                            </div>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <Text variant={isOnlyEmoji ? "h3" : "body2"} component={"p"} style={{ color: isOnlyEmoji ? null : (isMine ? "white" : "black") }}> {isMine ?`Audio Call.`: `${caller} called you.` }</Text>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10} xl={10} style={{ display: "flex", justifyContent: "center" }}>
                            <Text variant="caption" style={{ color: isOnlyEmoji ? null : (isMine ? "white" : "black") }}>{getTime()}</Text>
                        </Grid>
                </>
                }
                {message?.type == "image" && message?.images?.length > 0 && <div style={{ margin: "12px 0", width: "300px", }}>
                    <AttachmentHandler maxHeight="300px" images={message?.images} />
                </div>}
            </div>
        </Tooltip>
        { read && read[otherUser] == 0 && !nextContinue && isMine && isLast && userData.uid != otherUser &&
         <div
         style={{
           marginLeft: '30px'
         }}
       >
        <AiFillEye style={{ color: "grey" }} size={14} /><span>seen</span>
        </div>
        }
        </>
    )
}

export default MessageListItem;