import React, { useEffect, useState } from "react";
import { useParams, useMatch } from "@reach/router";
import { tasks, users } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import { UserData } from "../../models/user-data";
import MessageViewerBottom from "./message-viewer-bottom";
import Avatar from "../../components/avatar/avatar";
import Text from "../../components/data-display/text";
import MessageViewerList from "./message-viewer-list";
import { auth } from "../../utils/firebase.utils";
import { MessageStatus } from "../../models/private-messaging-instance";
import MessageWithAdmin from "./message-with-admin";

const MessageViewer = () => {
  const params = useParams();
  const msgMatch = useMatch("/messaging/:id");
  const [user, setUser] = useState<UserData>(null);
  const [isAdminType, setIsAdminType] = useState<boolean>(false);
  const { userData } = useSelector((state: RootState) => state.userData);
  const [messagingAdmin, setMessagingAdmin] = useState<MessageStatus[]>([]);

  useEffect(() => {
    let isAdmin: boolean = msgMatch.id.includes('admin') ? true : false;
    setIsAdminType(isAdmin)
    if(!isAdmin){
      let taskId = msgMatch?.id.split("_")[0];
      let offerId = msgMatch?.id.split("_")[1];
      setUser(null);
  
      if (taskId && offerId) {
        tasks
          .doc(taskId)
          .collection("offers")
          .doc(offerId)
          .get()
          .then(async (res) => {
            let taskerUid = res.data()["uid"];
  
            if (taskerUid !== userData?.uid) {
              let u = await users.doc(taskerUid).get();
              setUser(new UserData(u?.data()));
            } else {
              let task = await tasks.doc(taskId).get();
              let posterUid = task.data()["uid"];
  
              let u = await users.doc(posterUid).get();
              setUser(new UserData(u?.data()));
            }
          });
      }
    }
  }, [msgMatch?.id]);


  

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div
        style={{
          zIndex: 1300,
          padding: "0 24px",
          boxSizing: "border-box",
          backdropFilter: "blur(10px)",
          background: "rgba(254, 254, 254, 0.8)",
          borderBottom: "2px solid rgb(240,240,240,1)",
          height: "64px",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
      {isAdminType ? <Avatar
        
        profilePicture={
          "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/icon.jpg?alt=media&token=1ae7617e-2390-41c6-9e8b-40502efd7cfe"
        }
          size={22}
        /> : <Avatar
          userId={user?.slug_title}
          name={user?.name}
          profilePicture={user?.profilePicture}
          size={22}
        />} 
        {isAdminType ? <Text variant={"body2"} bold style={{ marginLeft: "12px" }}>SydeTasker</Text> :<Text variant={"body2"} bold style={{ marginLeft: "12px" }}>
          {user?.name}
        </Text>}
      </div>
      <MessageViewerList />
      <MessageViewerBottom isAdmin={isAdminType} otherUser={user} />
    </div>
  );
};

export default MessageViewer;
