import React from "react";
import Text from '../data-display/text';
import { FaPlayCircle, FaPauseCircle, } from "react-icons/all";
import moment from "moment";
import useTheme from "@material-ui/core/styles/useTheme";


const Bar = ({ duration, curTime, width, isMine, isPlaying, togglePlay, style }) => {
    const theme = useTheme();
    const curPercentage = (curTime / duration) * 100;
    const time = moment.duration(duration);

    const formatDuration = ((duration) => {
        const d = duration / 60;
        if (duration < 10 && duration > 1) {
            return (`00:0${duration.toString().slice(0, 1)}`);
        } else if (duration < 60 && duration > 10) {
            return (`00:${duration.toString().slice(0, 2)}`);
        }
        else if (duration > 1 && duration > 60) {
            return (d.toString().slice(0, 4).replace('.', ':'));
        }
    })


    return (
        <div style={{
            userSelect: "none",
            width: '100%',
            display: 'flex',
            alignItems: 'center', background: `linear-gradient(to right, ${isMine ? "rgb(64 52 186)" : "rgb(206 206 206)"} ${curPercentage}%, ${isMine ? "rgb(64 52 186)" : "rgba(224,224,224,0.6)"} 0)`, cursor: "pointer", height: "100%", ...style
        }}>

            <span>               
            {!isPlaying ? <FaPlayCircle style={{ marginRight: "0px" }} color={isMine ? "white" : "#8b8989"} onClick={() => togglePlay(true)} size={20} /> : <FaPauseCircle style={{ marginRight: "0px" }} color={isMine ? "white" : "#8b8989"} onClick={() => togglePlay(false)} size={20} />}
            </span>
            <div
                style={{
                    background: `linear-gradient(to right, ${theme.palette.primary.main} ${curPercentage}%, ${isMine ? "white" : "#8b8989"} 0)`,
                    flex: 1,
                    borderRadius: '5px',
                    margin: '0 20px',
                    height: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
            >
                <span
                    style={{
                        left: `${curPercentage - 2}%`, position: 'relative', height: "16px",
                        width: "16px",
                        borderRadius: "50%",
                        border: `0.5px ${isMine ? "white" : "#8b8989"} solid`,
                        backgroundColor: isMine ? "white" : "#8b8989", height: "0px", marginTop: "auto", marginBottom: "auto"
                    }}
                />
            </div>
            <span>{formatDuration(duration - curTime)}</span>
        </div>
    );
}
export default Bar;