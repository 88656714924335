import React, { useEffect, useState } from "react";
import { useParams, useMatch } from "@reach/router";
import { firestore, tasks } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import FormInput from "../../components/forms/form-input";
import Avatar from "../../components/avatar/avatar";
import Alert from "@material-ui/lab/Alert";
import { myTaskAttachments } from "../../utils/firebase.utils";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  FiX,
  AiFillCheckCircle,
  GrAttachment,
  IoMdSend,
} from "react-icons/all";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormWrapper from "../../components/forms/form-wrapper";
import { Message } from "../../models/private-message";
import { Button, Divider } from "@material-ui/core";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import Text from "../data-display/text";
import moment from "moment";
import { UserData } from "../../models/user-data";
import { isToday } from "../../utils/methods.utils";
import { MessageStatus } from "../../models/private-messaging-instance";
import ListContainer from "../list-handlers/list-container";
// import Loader from "react-loader-spinner";
import firebase from "firebase";
import { Task } from "../../models/task";
import { FiInfo } from "react-icons/fi";
import { FiAlertTriangle } from "react-icons/fi";
import { endPoint, storageFB } from "../../utils/constant.urls";

const useStyles = makeStyles(() => ({
  previewDeleteIcon: {
    cursor: "pointer",
    borderRadius: "50%",
    background: "rgba(0, 0, 0,0.8)",
    position: "absolute",
    right: "8px",
    top: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "20px",
    width: "20px",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  previewContainer: {
    position: "relative",
    height: "80px",
    boxShadow: "0 4px 11px 0.2px rgba(0,0,0,0.06)",
    display: "inline-block",
    margin: "8px",
    overflow: "hidden",
    borderRadius: "8px",
    width: "80px",
  },
  progressBar: {
    color: "white",
  },
  progressBackground: {
    color: "rgba(255,255,255,0.5)",
  },
  loading: {
    color: "gray",
  },
}));

const MessageViewerBottom = ({
  otherUser,
  isAdmin,
}: {
  otherUser: UserData | null;
  isAdmin: boolean;
}) => {
  const params = useParams();
  const msgMatch = useMatch("/messaging/:id");

  const [typing, setTyping] = useState<boolean>(false);
  const [internalTyping, setInternalTyping] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles();
  const { userData } = useSelector((state: RootState) => state.userData);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<(IFileWithMeta | string)[]>(
    []
  );
  const [attachmentsError, setAttachmentsError] = useState<{
    file: (string | IFileWithMeta)[] | null;
    errorMsg: string;
  } | null>(null);
  const [uploadedMedia, setUploadedMedia] = useState<string[]>([]);
  const [progress, setProgress] = useState<number[]>([0]);
  const [task, setTask] = useState<Task>();
  const [expired, setExpired] = useState<boolean>(false);
  const [warning, setWarning] = useState<boolean>(false);
  const [numberWarning, setNumberWarning] = useState<boolean>(false);


  const inputEl = React.useRef(null);
  const onButtonClick = () => {
    // `current` points to the mounted text input element
    inputEl.current.click();
  };

  let taskId = msgMatch?.id.split("_")[0];
  let offerId = msgMatch?.id.split("_")[1];
  useEffect(() => {
    let taskId = msgMatch?.id.split("_")[0];
    let offerId = msgMatch?.id.split("_")[1];


    if (isAdmin) {
      const resposne = firestore
        .collection("messaging_with_admin")
        .doc(msgMatch?.id)
        .collection("messages")
        .onSnapshot(async (value: any) => {
         
        });

      return;
    }
    setTyping(false);
    setInternalTyping(false);
    let messageStatusStream = tasks
      .doc(taskId)
      .collection("offers")
      .doc(offerId)
      .collection("messaging")
      .doc(msgMatch?.id)
      .onSnapshot(async (snapshot) => {
        const t = await tasks.doc(taskId).get();
        let taskData = t.data();
        setTask(new Task(taskData));
        if (snapshot.exists) {
          let data = snapshot.data()["typing"];
          let allData = snapshot.data();
          let expired = new Date(allData?.expired?.toDate().toString());
          if (expired <= new Date()) {
            setExpired(true);
          } else {
            setExpired(false);
          }
          Object.keys(data).forEach((key) => {
            if (key !== userData?.uid) setTyping(data[key]);
          });
        } else {
          let task = await tasks.doc(taskId).get();
          let taskData = task.data();
          taskData["id"] = typeof task?.id === "undefined" ? "" : task?.id!;
          let posterUid = taskData["uid"];
          let title = taskData["title"];
          let offer = await tasks
            .doc(taskId)
            .collection("offers")
            .doc(offerId)
            .get();

          let taskerUid = offer.data()["uid"];

          tasks
            .doc(taskId)
            .collection("offers")
            .doc(offerId)
            .collection("messaging")
            .doc(msgMatch?.id)
            .set({
              expired: null,
              title: title,
              poster: posterUid,
              tasker: taskerUid,
              typing: {
                [posterUid]: false,
                [taskerUid]: false,
              },
              users: [posterUid, taskerUid],
              lastRead: {
                [posterUid]: null,
                [taskerUid]: null,
              },
              unRead: {
                [posterUid]: 0,
                [taskerUid]: 0,
              },
            })
            .then();
        }
      });

    return () => {
      messageStatusStream();
    };
  }, [msgMatch?.id, isAdmin]);

  useEffect(() => {
    let listLength = [];
    for (let index = 0; index < attachments.length; index++) {
      listLength.push(0);
    }
    setProgress(listLength);
  }, [attachments.length]);

  const handleChangeStatus = (
    file: IFileWithMeta,
    status: string,
    allFiles: IFileWithMeta[]
  ) => {
    if (status === "removed") {
      let f = attachments.find(
        (a) => typeof a !== "string" && a.meta.id === file.meta.id
      );
      let a = [...attachments];
      a.splice(a.indexOf(f), 1);
      setAttachments([...a]);
    } else if (status === "done") {
      let a = [...attachments];
      allFiles.forEach((file) => {
        if (
          !a.find(
            (item) => typeof item !== "string" && item.meta.id === file.meta.id
          )
        ) {
          a.push(file);
        }
      });
      setAttachments([...a]);
    }
  };
  const handleRemove = (f: IFileWithMeta | string) => (event: any) => {
    event.preventDefault();
    if (typeof f !== "string" && attachmentsError?.file?.includes(f)) {
      setAttachmentsError(null);
    }
    if (typeof f !== "string") f.remove();
    else {
      let a = [...attachments];
      a.splice(a.indexOf(f), 1);
      setAttachments([...a]);
    }
  };
  const uploadImages = async () => {
    return new Promise((resolve, reject) => {
      let hasLargeVideo = attachments?.find((file) => {
        if (
          typeof file !== "string" &&
          file?.meta?.type?.includes("video") &&
          file?.meta?.duration > 95
        ) {
          return file;
        }
      });
      let multipleVideos = attachments?.filter((file) => {
        if (typeof file !== "string" && file?.meta?.type?.includes("video")) {
          return file;
        }
      });
      if (hasLargeVideo) {
        setAttachmentsError(
          typeof hasLargeVideo !== "string"
            ? {
                file: [hasLargeVideo],
                errorMsg: "the max video duration is 1:30 m:s!",
              }
            : null
        );
        reject("");
      } else if (multipleVideos?.length > 1) {
        setAttachmentsError({
          file: multipleVideos?.filter((f) => {
            if (typeof f !== "string") return f;
          }),
          errorMsg: "not allowed to attach more than 1 video.",
        });
        reject("");
      } else {
        setLoading(true);
        let uploaded: string[] = [];
        let progressItems = [...progress];
        attachments.forEach((a, index) => {
          if (typeof a === "string") uploaded.push(a);
          else {
            let uploadTask = myTaskAttachments(userData?.uid)
              .child(a.file.name)
              .put(a.file);
            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED,
              (snapshot) => {
                progressItems[index] =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progressItems);

                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED:
                    break;
                  case firebase.storage.TaskState.RUNNING:
                    break;
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async (downloadURL) => {
                    downloadURL = downloadURL.replace(storageFB, endPoint);
                    uploaded.push(downloadURL);
                  })
                  .then((result) => {
                    if (uploaded?.length === attachments?.length) {
                      setLoading(false);
                      setUploadedMedia(uploaded);
                      resolve("sucess");
                    }
                  });
              }
            );
          }
        });
        // if (attachments.length === uploaded.length) {
        //     setLoading(false);
        //     resolve(uploaded);
        // }
      }
    });
  };
  const sendMessage = (values) => {
    return new Promise((resolve) => {
      if (attachments.length > 0) {
        if (uploadedMedia.length === attachments.length) {
          tasks
            .doc(taskId)
            .collection("offers")
            .doc(offerId)
            .collection("messaging")
            .doc(msgMatch?.id)
            .update({
              [`typing.${userData?.uid}`]: false,
              [`unRead.${otherUser?.uid}`]:
                firebase.firestore.FieldValue.increment(1),
              updatedAt: new Date(),
              lastMessage: {
                uid: userData?.uid,
                message: `[ 📷 ${uploadedMedia.length} Image Attachment ]`,
              },
            })
            .then()
            .catch((e) => console.log(e));
          tasks
            .doc(taskId)
            .collection("offers")
            .doc(offerId)
            .collection("messaging")
            .doc(msgMatch?.id)
            .collection("messages")
            .add(
              new Message({
                text: `[ 📷 ${uploadedMedia.length} Image Attachment ]`,
                uid: userData?.uid,
                type: "image",
                images:
                  attachments.length > 0 && uploadedMedia.length > 0
                    ? uploadedMedia
                    : [],
                createAt: new Date(),
              }).toJson()
            )
            .then((res) => {
              attachments.forEach((f) => {
                if (typeof f !== "string") {
                  const file = f;
                  file.remove();
                } else {
                  let a = [...attachments];
                  a.splice(a.indexOf(f), 1);
                  setAttachments([...a]);
                }
              });
              setUploadedMedia([]);
              setProgress([0]);
              resolve("Success");
            });
        }
      } else {
        tasks
          .doc(taskId)
          .collection("offers")
          .doc(offerId)
          .collection("messaging")
          .doc(msgMatch?.id)
          .update({
            [`typing.${userData?.uid}`]: false,
            [`unRead.${otherUser?.uid}`]:
              firebase.firestore.FieldValue.increment(1),
            updatedAt: new Date(),
            lastMessage: {
              uid: userData?.uid,
              message: values?.message?.toString(),
            },
          })
          .then()
          .catch((e) => console.log(e));
        tasks
          .doc(taskId)
          .collection("offers")
          .doc(offerId)
          .collection("messaging")
          .doc(msgMatch?.id)
          .collection("messages")
          .add(
            new Message({
              text: values?.message?.toString(),
              uid: userData?.uid,
              type: "text",
              createAt: new Date(),
            }).toJson()
          )
          .then();
        resolve("Success");
      }
    });
  };

  const updateTyping = (values: Record<string, string | boolean>) => {
    const regex = /\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m;  
    let value = values.message && values.message.toString().length > 0;
    if (value !== internalTyping){
        tasks.doc(taskId).collection("offers").doc(offerId).collection("messaging").doc(msgMatch?.id).update({ [`typing.${userData?.uid}`]: !!value }).then()
    }else{
        if(values.message.toString().toLocaleLowerCase() == "payment" || values.message.toString().toLocaleLowerCase() == "cash" || values.message.toString().toLocaleLowerCase() == "money" || values.message.toString().toLocaleLowerCase() == "venmo" || values.message.toString().toLocaleLowerCase() == "paypal" || values.message.toString().toLocaleLowerCase() == "money" || values.message.toString().toLocaleLowerCase() == "venmo" || values.message.toString().toLocaleLowerCase() == "pay" || values.message.toString().toLocaleLowerCase() == "dinero" || values.message.toString().toLocaleLowerCase() == "zelle" || values.message.toString().toLocaleLowerCase() == "money" || values.message.toString().toLocaleLowerCase() == "venmo" || values.message.toString().toLocaleLowerCase() == "paypal" || values.message.toString().toLocaleLowerCase() == "money" || values.message.toString().toLocaleLowerCase() == "venmo" || values.message.toString().toLocaleLowerCase() == "pay" || values.message.toString().toLocaleLowerCase() == "dinero" || values.message.toString().toLocaleLowerCase() == "cashapp" ||  values.message.toString().toLocaleLowerCase() == "cashapp" ){
         setWarning(true);
        }else if(regex.test(values.message.toString())){
        setNumberWarning(true);
        }else{
            setWarning(false);
            setNumberWarning(false);
        }
    }
    setInternalTyping(value);
}

  return (
    <>
      <div
        style={{
          zIndex: 1501,
          borderRadius: "8px",
          background:
            "linear-gradient(0deg, rgba(247, 248, 251, 1), rgba(247, 248, 251, 0))",
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-10px",
            left: "32px",
            opacity: typing ? "1" : 0,
            transition: "0.3s",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "white",
              borderRadius: "48px",
              padding: "4px 6px",
            }}
          >
            <Avatar
              userId={otherUser?.slug_title}
              name={otherUser?.name}
              profilePicture={otherUser?.profilePicture}
              size={16}
            />
            <div style={{ width: "8px" }} />
            <div style={{ transform: "translateY(-2px)" }}>
              {/* <Loader type="ThreeDots" color="rgb(220,220,220)" height={10} width={36} /> */}
            </div>
          </div>
        </div>
        <Dropzone
          multiple={true}
          inputContent={
            <p
              key={"dropzone"}
              ref={inputEl}
              style={{
                width: "15px",
                background: "white",
                zIndex: 1333,
                position: "absolute",
                marginRight: "0px",
              }}
            ></p>
          }
          inputWithFilesContent={
            <p
              key={"dropzone"}
              ref={inputEl}
              style={{
                textAlign: "center",
                padding: "0 16px",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
              }}
            ></p>
          }
          submitButtonDisabled={true}
          PreviewComponent={null}
          onChangeStatus={handleChangeStatus}
          disabled={loading}
          accept="image/jpg,image/JPEG,image/jpeg,image/png,image/PNG,image/gif,image/GIF,image/tiff,image/TIFF,image/raw,image/RAW,video/*"
          styles={{
            input: { display: "none" },
            dropzone: {
              display: "none",
              zIndex: 1000,
              position: "absolute",
              border: "2px solid rgb(240,240,240)",
              borderRadius: "32px",
              boxShadow: "0 4px 18px 0 rgba(0,0,0,0.04)",
              padding: "8px",
              width: "15px",
              background: "white",
              overflow: "hidden",
            },
            dropzoneDisabled: {
              display: "none",
              zIndex: 1333,
              position: "absolute",
              width: "calc(100% - 48px)",
              background: "white",
              border: "2px solid rgb(240,240,240)",
              borderRadius: "32px",
              boxShadow: "0 4px 18px 0 rgba(0,0,0,0.04)",
            },
            dropzoneActive: {
              display: "none",
              zIndex: 1000,
              position: "absolute",
              width: "calc(100% - 48px)",
              background: "rgba(78, 62, 253,0.13)",
              border: "2px solid rgb(240,240,240)",
              borderRadius: "32px",
              boxShadow: "0 4px 18px 0 rgba(0,0,0,0.04)",
            },
            dropzoneReject: {
              zIndex: 1333,
              position: "absolute",
              width: "calc(100% - 48px)",
              background: "rgb(235, 80, 60, 0.13)",
              border: "2px solid rgb(240,240,240)",
              borderRadius: "32px",
              boxShadow: "0 4px 18px 0 rgba(0,0,0,0.04)",
            },
          }}
        />
        <div
          style={{
            width: 'calc(100% - 48px)', background: "white" 
          }}
        >
          {warning && (
            <ListContainer >
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "12px 16px",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                <FiAlertTriangle  color="#FF8800" size={16} style={{ opacity: "0.7", marginTop: "6px" }} />
                </div>
                <div style={{ marginLeft: "12px" }}>
                <Text variant="caption" style={{ marginBottom: "4px",color:"#FF8800" }}>
                            Payment is completed only through the Sydetasker Payment Platform. <a style={{color:"#FF8800",textDecoration:"underline"}} href="https://sydetasker.com/support/taskers/payments-and-withdrawals">Learn more.</a>
                            </Text>
                </div>
              </div>
            </ListContainer>
          )}
            {numberWarning &&  <ListContainer style={{  }}>
                    <div style={{ width: "100%", display: 'flex', alignItems: "flex-start", padding: "12px 16px", justifyContent: "flex-start" }}>
                        <div>
                            <FiAlertTriangle  color="#FF8800" size={16} style={{ opacity: "0.7", marginTop: "6px" }} />
                        </div>
                        <div style={{ marginLeft: "12px" }}>
                            <Text variant="caption" style={{ marginBottom: "4px",color:"#FF8800" }}>
                             You are able to securely call each other to talk about the job over the phone or show them in a video chat through our app! 
                            </Text>
                        </div>
                    </div>
                </ListContainer>}
          {!expired && !isAdmin && otherUser?.isDeleted !=true  &&  (
            <FormWrapper
              reset
              disableLoading
              onSubmit={
                attachments.length > 0 &&
                !(uploadedMedia?.length === attachments?.length)
                  ? uploadImages
                  : sendMessage
              }
              onValueChange={updateTyping}
            >
              {attachments.length <= 0 && (
                <FormInput
                  required={attachments.length <= 0 ? true : false}
                  name={"message"}
                  placeholder={"Message..."}
                  bare
                  autoCapitalize={"sentences"}
                  startAdornment={
                    <Avatar
                      userId={userData?.slug_title}
                      name={userData?.name}
                      profilePicture={userData?.profilePicture}
                      size={20}
                      style={{ marginLeft: "16px" }}
                    />
                  }
                  endAdornment={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={onButtonClick}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        variant={"text"}
                        style={{
                          marginRight: "0px",
                          padding: "4px 12px",
                          textTransform: "capitalize",
                        }}
                      >
                        <GrAttachment size={20} color={"blue"} />
                      </Button>
                      {(attachments.length <= 0 ||
                        (attachments.length > 0 &&
                          uploadedMedia?.length === attachments?.length)) && (
                        <Button
                          disabled={
                            attachments.length > 0 &&
                            !(uploadedMedia?.length === attachments?.length)
                          }
                          type={"submit"}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          variant={"text"}
                          style={{
                            marginRight: "16px",
                            padding: "4px 12px",
                            textTransform: "capitalize",
                          }}
                        >
                          <IoMdSend size={20} color={"blue"} />
                        </Button>
                      )}
                      {attachments.length > 0 &&
                        !(uploadedMedia?.length === attachments?.length) && (
                          <Button
                            type={"submit"}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant={"text"}
                            style={{
                              marginRight: "16px",
                              padding: "4px 12px",
                              textTransform: "capitalize",
                            }}
                          >
                            <IoMdSend size={20} color={"blue"} />
                          </Button>
                        )}
                    </div>
                  }
                />
              )}
              {attachments.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    overflowX: "auto",
                    padding: "10px 0px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {attachments.map((f, i) => {
                    if (typeof f !== "string")
                      return (
                        <div
                          key={"div2" + i}
                          className={classes.previewContainer}
                          style={{
                            backgroundImage: `url(${f.meta.previewUrl})`,
                            backgroundSize: "cover",
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                background: "rgba(0,0,0,0.5)",
                                top: 0,
                                position: "absolute",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {progress[i] === 100 ? (
                                <AiFillCheckCircle size={32} color={"white"} />
                              ) : (
                                <div
                                  style={{
                                    transform: "translate(-18px, -18px)",
                                  }}
                                >
                                  <CircularProgress
                                    variant={"determinate"}
                                    style={{ position: "absolute" }}
                                    value={progress[i]}
                                    classes={{
                                      colorPrimary: classes.progressBar,
                                    }}
                                    size={40}
                                    thickness={12}
                                  />
                                  <CircularProgress
                                    variant={"determinate"}
                                    style={{ position: "absolute" }}
                                    value={100}
                                    classes={{
                                      colorPrimary: classes.progressBackground,
                                    }}
                                    size={40}
                                    thickness={12}
                                  />
                                </div>
                              )}
                            </div>
                          ) : progress[i] === 100 ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                background: "rgba(0,0,0,0.5)",
                                top: 0,
                                position: "absolute",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <AiFillCheckCircle size={32} color={"white"} />
                              <div
                                className={classes.previewDeleteIcon}
                                onClick={handleRemove(f)}
                              >
                                <FiX
                                  style={{
                                    height: "14px",
                                    width: "14px",
                                    color: "white",
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className={classes.previewDeleteIcon}
                              onClick={handleRemove(f)}
                            >
                              <FiX
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  color: "white",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                  })}
                </div>
              )}
              {attachmentsError !== null && (
                <Alert severity="error">{attachmentsError?.errorMsg}</Alert>
              )}
              {attachments.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={loading}
                    onClick={onButtonClick}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    variant={"text"}
                    style={{
                      marginRight: "10px",
                      marginBottom: "8px",
                      padding: "4px 12px",
                      textTransform: "capitalize",
                    }}
                  >
                    <GrAttachment size={20} color={"blue"} />
                  </Button>
                  {!loading ? (
                    <Button
                      disabled={loading}
                      type={"submit"}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      variant={"text"}
                      style={{
                        marginRight: "15px",
                        marginBottom: "8px",
                        padding: "4px 12px",
                        textTransform: "capitalize",
                      }}
                    >
                      <IoMdSend size={20} color={"blue"} />
                    </Button>
                  ) : (
                    <CircularProgress
                      classes={{ colorPrimary: classes.loading }}
                      size={12}
                      thickness={6}
                    />
                  )}
                </div>
              )}
            </FormWrapper>
          )}

          { expired && (
            <div
              style={{
                height: "100px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FiInfo
                  style={{ color: "black", margin: "auto 0px" }}
                  size={20}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Text
                  variant={"body2"}
                  bold
                  style={{ marginTop: "6px", marginLeft: "16px" }}
                >
                  Private messaging is no longer available. You may view your
                  messaging history only.
                </Text>
                <div style={{ height: "10px" }} />
                {task?.assignedWorker?.uid != userData?.uid && (
                  <Text
                    variant={"caption"}
                    bold
                    style={{ marginTop: "6px", marginLeft: "16px" }}
                  >
                    If you would like to hire {task?.assignedWorker?.name}{" "}
                    again, please select his/her profile above and click Request
                    Quote.
                  </Text>
                )}
              </div>
            </div>
          )}
  { otherUser && otherUser?.isDeleted==true && (
            <div
              style={{
                height: "60px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FiInfo
                  style={{ color: "black", margin: "auto 0px" }}
                  size={20}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                    <Text
                  variant={"body2"}
                  bold
                  style={{ marginTop: "6px", marginLeft: "16px" }}
                >
                  Private messaging is not available for this chat. 
                </Text>
               
              </div>
            </div>
          )}
          {isAdmin  && (
            <div
              style={{
                height: "100px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FiInfo
                  style={{ color: "black", margin: "auto 0px" }}
                  size={20}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                    <Text
                  variant={"body2"}
                  bold
                  style={{ marginTop: "6px", marginLeft: "16px" }}
                >
                  Private messaging is not available for this chat. 
                </Text>
               
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageViewerBottom;
