import React, { useEffect } from "react";
import Text from "../../components/data-display/text";
import { Container } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMatch,Router,useLocation } from "@reach/router";
import MessageViewer from "../../components/messaging/message-viewer";
import MessageInstanceList from "../../components/messaging/message-instances-list";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PrivateRoute } from "../../components/app-routes/app-routes";
import SEO from "../../components/seo/seo";

const useStyles = makeStyles((theme) => ({
  drawer: {
    padding: "16px 24px",
    width: "calc(1280px/3)",
    height: "calc(100vh - 134px)",
    boxSizing: "border-box",
    borderRight: "2px solid rgb(240,240,240)",
    position: "relative"
  },
  drawerPaper: {
    transition: "0.3s",
    background: theme.palette.background.default,
    width: "calc(1280px/3)",
    left: "calc((100% - 1280px)/2)",
    height: "calc(100vh - 88px)",
    border: "none",
    top: "88px",
    [theme.breakpoints.down("md")]: {
      width: "calc(960px/(12/5))",
      left: "calc((100% - 960px)/2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 48px)",
      left: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 24px)",
      left: "12px"
    },
},
  drawerPaperHidden: {
    transition: "0.3s",
    background: theme.palette.background.default,
    width: "calc(1280px/3)",
    left: "calc((100% - 1280px)/2)",
    height: "calc(100vh - 88px)",
    border: "none",
    top: "88px",
    [theme.breakpoints.down("md")]: {
      width: "calc(960px/(12/5))",
      left: "calc((100% - 960px)/2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 48px)",
      left: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 24px)",
      left: "12px"
    },
    transform: "translateX(-120%)"
},
  content: {
    flex: 1,
    height: "calc(100vh - 134px)",
    boxSizing: "border-box"
  }
}));

const Messaging = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up("md"));
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const messageMatch = useMatch("/messaging/:id")


    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    return (
      <>
      <SEO title={'Sydetasker - Post. Hire. Done.'} pathname={"/messaging"} description={'Any task you need done? Sydetasker easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, yard work, & more.'} />
 
        <>
        <Container fixed={mdUp}  maxWidth={"lg"} style={{ marginTop: "100px", height: "calc(100vh - 100px)"  }}>
        <div style={{ display: "flex", background: "white", borderRadius: "6px", boxShadow: "0 1px 2px rgb(0,0,0,0.1)", overflow: "hidden" }}>
           {xsDown &&  messageMatch?.id ? "":
           <div className={  classes.drawer }>
                <div style={{
                    zIndex: 1000,
                    padding: "0 24px",
                    boxSizing: "border-box",
                    backdropFilter: "blur(10px)",
                    background: "rgba(254, 254, 254, 0.8)",
                    borderBottom: "2px solid rgb(240,240,240,1)",
                    height: "64px",
                    position: "absolute", top: "0", left: "0", width: "100%",
                    display: "flex", alignItems: "center", justifyContent: "flex-start"
                }}>
                    <Text variant={"h6"} bold >Chat</Text>
                </div>
                <div style={{ height: "64px" }}/>
                <MessageInstanceList/>
            </div>}
            <div className={classes.content} >
                {
                    messageMatch ?
                       <MessageViewer/>
                        :
                       !xsDown&& <div style={{ width: '100%', height: '100%', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <Text variant={"body1"} bold>
                                You don’t have a message selected
                            </Text>
                            <Text variant={"body2"} regular style={{ color: "grey", maxWidth: "350px", textAlign: "center", marginTop: "8px" }}>
                                All messages are private conversations between Taskers & Posters on Sydetasker.
                            </Text>
                        </div>
                }
            </div>
        </div>
    </Container>
    </>
      </>
  );
};


const App=()=> {
  const location= useLocation()
  return (
      <div className="content">
        <Router location={location}>
            <PrivateRoute path={"/messaging/*"} component={Messaging}/>
          </Router>
       </div>
  )
}

export default App;



