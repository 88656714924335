export class LastMessage {
    message: string | null = null;
    uid: string = null;

    constructor(data?: { [field: string]: any }) {
        this.uid = data?.uid ?? null;
        this.message = data?.message ?? null;
    }

    toJson(): LastMessage {
        return Object.assign({}, this);
    }
}

export class MessageStatus {
    id: string | null = null;
    poster: string | null = null;
    tasker: string | null = null;
    title: string | null = null;
    lastMessage: LastMessage;
    lastRead: Record<string, Date> = {};
    typing: Record<string, boolean> = {};
    unRead: Record<string, number> = {};
    users: string[] = [];
    expired: Date = null;
    updatedAt: Date = null;
    admin :string | null = null;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? null;
        this.poster = data?.poster ?? null;
        this.tasker = data?.tasker ?? null;
        this.title = data?.title ?? null;
        this.admin = data?.admin??null;
        this.lastMessage = data?.lastMessage != null ? new LastMessage(data.lastMessage) : null;
        if (data?.lastRead != null) {
            let t: Record<string, Date> = {};
            Object.keys(data?.lastRead).forEach((key) => {
                let timestamp = data?.lastRead["key"];
                t[key] = timestamp ? (new Date(timestamp.toDate().toString())) : null;
            })
            this.lastRead = t;
        } else {
            this.lastRead = {};
        }
        this.typing = data?.typing != null ? data.typing : {};
        this.unRead = data?.unRead != null ? data.unRead : {};
        this.users = data?.users!=null ? data.users : [];
        this.expired = data?.expired ? (typeof data?.expired === "string" ? new Date(data.expired) : (data.expired instanceof Date ? data.expired : new Date(data.expired.toDate().toString()))) : null;
        this.updatedAt = data?.updatedAt ? (typeof data?.updatedAt === "string" ? new Date(data.updatedAt) : (data.updatedAt instanceof Date ? data.updatedAt : new Date(data.updatedAt.toDate().toString()))) : null;
    }

    toJson(): MessageStatus {
        return Object.assign({}, this);
    }

    get taskId(): string {
        return this.id.split("_")[0];
    }

    get offerId(): string {
        return this.id.split("_")[1];
    }
}